@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Lobster');

body,
html,
#root,
div.App {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
}

article {
  display: block;
  min-height: calc(100% - 100px)
}

article>div {
  max-width: 600px;
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

div.full {
  max-width: 100%;
  min-width: none;
}

footer {
  clear: both;
  height: 25px;
}

footer>div {
  max-width: 600px;
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.field-error {
  background: #ffeeee;
}

div.Login h1 {
  font-family: 'Lobster', cursive;
}

.loading-spin {
  font-size: 3em;
  animation: rotation 2s infinite linear;
  text-shadow: black 1px 1px 2px, #eee -1px -1px 2px;
  color: #1abc9c;
  line-height: 3em;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

/* Home */
div.Home h3 {
  font-family: 'Lobster', cursive;
  font-size: 32px;
  text-shadow: black 1px 1px 2px, #eee -1px -1px 2px;
  color: #1abc9c;
  margin: 20px 30px;
}

.loading {
  margin-left: 2em;
  margin-top: 2em;
}

.loading svg circle {
  text-shadow: black 1px 1px 2px, #eee -1px -1px 2px;
  color: #1abc9c;
}

/* div.Home fieldset  li:before {
  content: '<i class="fas fa-gift"></i>';
} */

div.Home fieldset li {
  display: block;
  line-height: 2em;
}

.fa-li {
  line-height: 2em;
  padding-top: 6px;
  text-shadow: black 1px 1px 2px, #eee -1px -1px 2px;
  color: #1abc9c;
}

/* App related */

div.App {
  width: 100%;
}

div.App>div {
  padding: 10px 15px;
}

div.App>div>h1 {
  font-size: 20px;
}

div.App nav {
  background-color: #1abc9c;
  padding: 0px;
  height: 50px;
  clear: both;
}

div.App nav>div,
div.App nav+div {
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  min-width: 320px;
}

div.App nav div.title {
  float: left;
  font-family: 'Lobster', cursive;
  font-size: 28px;
  padding-left: 5px;
  padding-top: 5px;
  color: white;
  text-shadow: 2px 2px 8px black;
}

@media only screen and (max-width: 400px) {
  div.App nav div.title {
    font-size: 24px;
    padding-left: 5px;
    padding-top: 10px;
  }
}

div.App nav div.menu {
  float: right;
  height: 50px;
  /*padding-right: 6%;*/
}


div.App nav div.menu a,
div.App nav button {
  display: block;
  color: #f2f2f2;
  text-align: center;
  /* height: 35px; */
  line-height: 35px;
  width: 50px;
  text-decoration: none;
  font-size: 17px;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 7px;
  float: left;
  border: none;
  background: none;
}

@media only screen and (max-width: 360px) {

  div.App nav div.menu a,
  div.App nav button {
    font-size: 16px;
    height: 35px;
    width: 40px;
    padding-top: 15px;
  }
}


div.App nav div.menu a:hover,
div.App nav button:hover {
  background-color: #ddd;
  color: black;
}

/* Wishes */
div.WishList span.date {
  font-weight: 300;
  padding: 3px 15px 3px 0px;
  font-size: 13px;
  color: #aaa;
}

div.WishList span.text {
  font-weight: 700;
  color: black;
  padding: 3px 10px 10px 10px;
  clear: right;
  display: block;
  border-bottom: 1px #f6f6f6 solid;
}

div.WishList form div.wish-item {
  margin-top: 5px;
}

/* Messages */

div.MessageList {
  min-width: 320px;
}

div.MessageList div span.date {
  display: block;
  clear: both;
  font: 300 12px 'Open Sans', sans-serif;
  width: 100%;
  text-align: center;
  color: black;
}

div.MessageList div span.text {
  display: block;
  position: relative;
  padding: 10px 20px;
  color: white;
  border-radius: 15px;
  clear: both;
  font: 400 15px 'Open Sans', sans-serif;
  margin: 10px;
}


div.MessageList div.inbound span.text {
  background-color: #E5E5EA;
  float: left;
  color: black;
}


div.MessageList div.outbound span.text {
  background-color: #1abc9c;
  float: right;
}

div.MessageList textarea,
div.WishList textarea {
  min-width: 100%;
  max-width: 100%;
}

/* Form related */

.error-msg {
  color: red;
  font-weight: bold;
  padding: 4px;
  margin-bottom: 1rem;
}

input.submitting {
  opacity: 0.5;
}

/* Settings */
form input.text-field,
.pure-button.pure-button-primary {
  display: block;
  margin-top: 10px;
}

.sender-button {
  margin-top: -14px;
  margin-bottom: -18px;
  padding: 0;
}

/* Admin */
.Admin span.date {
  font-weight: 300;
  padding: 3px 15px 3px 0px;
  font-size: 13px;
  color: #666;
  display: inline-block;
  clear: right;
}

.Admin span.name {
  font-weight: 530;
  display: inline-block;
  margin-right: 20px;
}

.Admin span.wishes {
  display: block;
  font-size: 15px;
}

.Admin ul.members {
  list-style-type: none;
}

.Admin ul.members>li {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px #f6f6f6 solid;
}