@import url('https://fonts.googleapis.com/css?family=Raleway');

.center {
    font-family: 'Raleway', sans-serif;
    background-color: #1abc9c;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
    width: 256px;
    margin-top: -10%;
}

.card>form {
    display: flex;
    flex-direction: column;
}

.card h1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-item {
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    margin-bottom: 1rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.form-submit {
    font-family: 'Raleway', sans-serif;
    height: 35px;
    color: #fff;
    background-color: #1abc9c;
    border: none;
    letter-spacing: 0.2rem;
    transition: 0.3s opacity ease;
    cursor: pointer;
}

.form-submit:hover {
    opacity: 0.9;
}

.form-submit.submitting:hover {
    opacity: 0.5;
}